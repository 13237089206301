import { addBookingsPage } from '../pages-actions';
import { MigrationFailReason, PageId } from '../../constants';
import {
  getAllSiteComponents,
  getFullComponentStructure,
  updateComponentStyle,
  updateControllerConfiguration,
} from '../editor-sdk-actions';
import { ComponentStructure, ComponentType } from '../../migration/domain';
import { getNewFormPageData } from './settings-data-transpiler';
import { getNewFormPageStyles } from './styles-transpiler';

export async function migrate(
  sdk,
  appToken,
  instance,
  translations,
  onNextStep,
) {
  onNextStep(1);
  try {
    await addBookingsPage(PageId.BOOKINGS_FORM_PAGE, sdk, appToken);
  } catch {
    throw MigrationFailReason.PAGES;
  }
  const allComponents = await getAllSiteComponents(sdk, appToken);
  const siteStructure: any[] = await (async () => {
    return Promise.all(
      allComponents.map((componentRef) =>
        getFullComponentStructure(sdk, appToken, componentRef),
      ),
    );
  })();
  const bookingsCheckoutWidgetId = '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  const formPageWidgetId = '985e6fc8-ce3f-4cf8-9b85-714c73f48695';
  const compStructuresCheckout: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.data?.widgetId === bookingsCheckoutWidgetId,
  );
  const compStructuresFormPage: ComponentStructure = siteStructure.find(
    (comp) =>
      comp?.data?.widgetId === formPageWidgetId,
  );

  if (!compStructuresCheckout) {
    throw new Error('compStructuresCheckout not found in form-page-migration');
  }

  if (compStructuresCheckout.style?.style?.properties) {
    console.log('Old Form Page Properties:');
    console.log(compStructuresCheckout);
    console.table(compStructuresCheckout.style.style.properties);
    console.log('New Form Page structure: (Before Change)');
    console.log(compStructuresFormPage);
    console.table(compStructuresFormPage?.style?.style?.properties);
    const newFormPageData = getNewFormPageData(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Layouts to write:');
    console.table(newFormPageData);
    onNextStep(2);
    if (newFormPageData) {
      try {
        if (!compStructuresFormPage) {
          throw new Error('compStructuresFormPage not found for action newFormPageData - errorCode: newFormPageData_compStructuresFormPage_not_found');
        }

        await updateControllerConfiguration(
          sdk,
          appToken,
          compStructuresFormPage.id,
          newFormPageData,
        );
      } catch {
        throw MigrationFailReason.DATA;
      }
    }
    const newFormPageStyles = getNewFormPageStyles(
      compStructuresCheckout.style.style.properties,
    );
    console.log('Styles to write:');
    console.table(newFormPageStyles);
    onNextStep(3);

    if (newFormPageStyles) {
      try {
        if (!compStructuresFormPage) {
          throw new Error('compStructuresFormPage not found for action newFormPageStyles - errorCode: newFormPageStyles_compStructuresFormPage_not_found');
        }

        await updateComponentStyle(
          sdk,
          appToken,
          compStructuresFormPage.id,
          newFormPageStyles,
        );
      } catch {
        throw MigrationFailReason.STYLES;
      }
    }
  } else {
    console.log('Old Form page is plain, no styles to migrate.');
  }
}
